<!--
 * @Author: 月魂
 * @Date: 2022-09-08 09:41:59
 * @LastEditTime: 2022-09-20 16:38:38
 * @LastEditors: 月魂
 * @Description: 主题活动列表
 * @FilePath: \silk-road\src\views\activities\list.vue
-->
<template>
  <div class="box">
    <div class="header">
      <img src="http://image.wearetogether.com.cn/8th/assets/list/header.png" alt="" class="header-img">
      <div class="header-text">
        <van-icon name="arrow-left" class="back" @click="handleBack" />
        <div class="title">{{ this.$t('activities.title') }}</div>
        <div class="enTitle" v-if="this.$t('activities.enTitle')">{{ this.$t('activities.enTitle') }}</div>
      </div>
    </div>
    <!-- 活动列表 -->
    <div class="list">
      <div class="item" @click="jump(activity)" v-for="activity in this.$t('activities.list')" :key="activity.id">
        <img class="top-img" :src="activity.imgUrl" alt="" width="100%">
        <div class="bottom">
          <div class="title">{{ activity.title }}</div>
          <div class="team">{{ activity.host }}</div>
          <div class="time" v-show="activity.time">{{ activity.time }}</div>
          <div class="address">{{ activity.address }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { Icon } from 'vant'
import { handleBack } from '../../utils/util'
Vue.use(Icon)

export default {
  name: 'ActivitiesList',
  components: {
  },
  data() {
    return {
    }
  },
  methods: {
    handleBack() {
      handleBack()
    },
    jump(item) { // 此处要根据活动内容判断进入相应的界面
      // 如果有list则进入列表页，如果有performList则进入指定剧目列表页，否则进入详情页
      if(item.list) {
        this.$router.push(`/activity-list/${item.id}`);
      } else if (item.performList) {
        this.$router.push({ path: '/specify-list', query: { title: item.title, playType: item.playType } });
      } else if (item.cont) {
         if (item.type) {
          this.$router.push(`/${item.type}-detail/${item.linkId}`)
        } else {
          this.$router.push(`/activity-detail/${item.id}`)
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
.box {
  min-height: 100vh;
  background: #fcf8ed;
  .header {
    color: #fff;
    word-break: break-word;
    position: relative;
    .header-img {
      display: block;
      width: 100%;
    }
    .header-text {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      .back {
        position: absolute;
        top: 8px;
        left: 16px;
        width: 16px;
        font-size: 16px;
      }
      .title {
        font-family: SourceHanSerifCN-Bold;
        font-size: 34px;
        color: #FFF6E2;
        position: absolute;
        top: 24px;
        left: 0;
        right: 0;
        margin: auto;
        text-align: center;
      }
      .enTitle {
        font-family: Arial;
        text-align: center;
        font-size: 12px;
        color: #FFF6E3;
        position: absolute;
        top: 70px;
        left: 0;
        right: 0;
        margin: auto;
      }
    }
  }
  .item {
      width: 100%;
      background-color: #fff;
      margin-top: 16px;
      border-radius: 10px;
      overflow: hidden;
      &:first-child {
        margin-top: 0;
      }
      .top-img {
        display: block;
        width: 100%;
        border-radius: 10px;
        overflow: hidden;
      }
      .bottom {
        padding: 14px 16px;
        display: flex;
        flex-wrap: wrap;
        font-family: SourceHanSansCN-Medium;
        .title {
          width: 100%;
          font-size: 18px;
          color: #4D4D4D;
        }
        .team {
          width: 100%;
          font-size: 12px;
          color: #999999;
          margin-top: 8px;
        }
        .time {
          font-size: 12px;
          margin-right: 8px;
          color: #999999;
          margin-top: 8px;
        }
        .address {
          font-size: 12px;
          color: #999999;
          margin-top: 8px;
        }
      }
    }
}
</style>
